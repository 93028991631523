/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-07",
    versionChannel: "nightly",
    buildDate: "2024-03-07T00:05:09.134Z",
    commitHash: "d3e12e4a240f545517c33b0c704a4fb197415154",
};
